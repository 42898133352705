import { createSlice } from '@reduxjs/toolkit';
import { Color } from '../../components/Module/Header/ButtonAction';
import { MODULE_STATE_KEY } from '../types';
import { setHeader } from './actions';

export interface ButtonAction {
  name: string;
  pathTo: string;
  color?: Color;
}

export interface HeaderState {
  title: string;
  breadcrumbItems?: string[];
  buttonActions?: ButtonAction[];
}

export interface ModuleState {
  header: HeaderState;
}

const initialState = { header: { title: '' } } as ModuleState;

export const moduleSlice = createSlice({
  name: MODULE_STATE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setHeader, (state, action) => {
      state.header = action.payload;
    });
  },
});

export default moduleSlice.reducer;
