import { Color } from './components/Module/Header/ButtonAction';
import { ClubStatus } from './pages/Clubs/shared/types';

interface AppConfig {
  api: {
    baseUrl: string;
  };
  colors: {
    clubStates: StateColor;
  };
}

type StateColor = {
  [key in ClubStatus]: Color;
};

export const appConfig: AppConfig = {
  api: {
    baseUrl: process.env.REACT_APP_BASE_URL_API || '',
  },
  colors: {
    clubStates: {
      Activo: 'success',
      Inactivo: 'danger',
      'Pendiente de pago': 'warning',
    },
  },
};
