import { Form, Formik } from 'formik';
import ObjectSchema, { ObjectShape } from 'yup/lib/object';
import ButtonSubmit from '../../../components/Buttons/Submit';
import Col from '../../../components/Col';
import Input from '../../../components/Input';
import InputSwitch from '../../../components/InputSwitch';
import Row from '../../../components/Row';
import { useGetAllFeaturesQuery } from '../../../services/api';
import { PlanPostRequest } from '../shared/types';
import FeatureGroup from './FeatureGroup';

interface Props {
  initialValues: InitialPlan;
  validationSchema: ObjectSchema<ObjectShape>;
  onSave: (plan: InitialPlan) => Promise<void>;
  isSaving: boolean;
}

export interface InitialPlan extends Omit<PlanPostRequest, 'features'> {
  features: Record<string, boolean>;
}

const hiddenParents = ['payments'];

const PlanForm = ({ initialValues, validationSchema, onSave, isSaving }: Props) => {
  const { data: features = [] } = useGetAllFeaturesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => onSave(values)}
    >
      {({ values, setFieldValue }) => (
        <Form className="was-validated" noValidate autoComplete="off">
          <Row>
            <Col sm={6} md={4} xl={3}>
              <Input label="Nombre" name="name" />
            </Col>
            <Col sm={6} md={4} xl={3}>
              <Input label="Moneda" name="currency" />
            </Col>
            <Col sm={6} md={4} xl={3}>
              <Input type="number" label="Precio" name="price" />
            </Col>
            <Col sm={6} md={4} xl={3}>
              <InputSwitch
                label="¿Es plan de test?"
                name="is_plan_test"
                checked={values.is_plan_test}
                onChange={() => setFieldValue('is_plan_test', !values.is_plan_test)}
              />
            </Col>
            {values.is_plan_test ? (
              <Col sm={6} md={4} xl={3}>
                <Input type="number" label="Días de prueba" name="test_days" />
              </Col>
            ) : null}
          </Row>

          <h4 className="card-title mt-3 mb-0">Permisos</h4>
          <hr className="my-3" />

          <Row>
            {features.map(feature =>
              hiddenParents.includes(feature.name) ? null : (
                <Col key={feature.id} sm={6} md={4} xl={3}>
                  <FeatureGroup
                    setFieldValue={setFieldValue}
                    groupFeature={feature}
                    selectedFeatures={values.features}
                  />
                </Col>
              ),
            )}
          </Row>
          <Row className="justify-content-center">
            <Col sm={12} md={6} xl={3}>
              <ButtonSubmit
                loading={isSaving}
                btnType="primary"
                className="mt-2"
                block
                rounded
                buttonText="Guardar"
              />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default PlanForm;
