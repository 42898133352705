import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Route } from '../../../../../routes';

interface Props {
  route: Route;
}

const PrimaryItem: React.FC<Props> = ({ route }) => {
  const location = useLocation();

  return (
    <li className={`nav-item ${location.pathname === route.to ? 'active' : ''}`}>
      <NavLink style={{ color: '#f16246' }} to={route.to} className="nav-item-hold">
        {route.icon && <i className={`nav-icon ${route.icon}`}></i>}
        <span className="nav-text">{route.name}</span>
      </NavLink>
      <div className="triangle"></div>
    </li>
  );
};

export default PrimaryItem;
