import { ApiEndpointQuery } from '@reduxjs/toolkit/dist/query/core/module';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useState } from 'react';
import { TableStyles } from 'react-data-table-component';
import { useAppDispatch } from '../redux/hooks';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useService: UseQuery<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  service: ApiEndpointQuery<any, any>;
}

interface DatatableUtils<T> {
  data?: T;
  filters: Record<string, string>;
  progressPending: boolean;
  customStyles?: TableStyles;
  responsive?: boolean;
  fixedHeader?: boolean;
  selectableRowsHighlight?: boolean;
  pagination?: boolean;
  paginationServer?: boolean;
  paginationComponentOptions: {
    rowsPerPageText: string;
    rangeSeparatorText: string;
  };
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (currentRowsPerPage: number, currentPage: number) => void;
  onFilter: (filters: Record<string, string>) => void;
}

const customStyles = {
  headCells: {
    style: {
      justifyContent: 'center',
      color: 'white',
      fontWeight: 'bold',
      backgroundColor: '#f16246',
      borderColor: '#f16246',
    },
  },
  cells: {
    style: {
      justifyContent: 'center',
    },
  },
};

export function useDatatable<T>({ useService, service }: Props): DatatableUtils<T> {
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState<Record<string, string>>({ page: '1', per_page: '10' });
  const { data, isFetching } = useService(filters);

  const onChangePage = (page: number) => {
    const newFilters = { ...filters, page: String(page) };
    onFilter(newFilters);
  };

  const onChangeRowsPerPage = (currentRowsPerPage: number, currentPage: number) => {
    const newFilters = {
      ...filters,
      page: String(currentPage),
      per_page: String(currentRowsPerPage),
    };
    onFilter(newFilters);
  };

  const onFilter = (filters: Record<string, string>) => {
    const newFilters = { ...filters, page: filters.page || '1' };
    setFilters(newFilters);
    dispatch(service.initiate(newFilters, { subscribe: true, forceRefetch: true }));
  };

  return {
    data: data ? (data as T) : undefined,
    filters,
    progressPending: isFetching,
    responsive: true,
    fixedHeader: true,
    pagination: true,
    paginationServer: true,
    selectableRowsHighlight: true,
    customStyles,
    paginationComponentOptions: {
      rowsPerPageText: 'Filas por página',
      rangeSeparatorText: 'de',
    },
    onChangePage,
    onChangeRowsPerPage,
    onFilter,
  };
}
