import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingPage from '../../../components/LoadingPage';
import { useModuleHeader } from '../../../hooks/useModuleHeader';
import { ApiError } from '../../../services/types';
import { showGeneralSuccess } from '../../../utils/alert';
import { handleApiError } from '../../../utils/errors';
import FeatureForm from '../Components/FeatureForm';
import { useGetFeatureQuery, usePutFeatureMutation } from '../shared/services/featuresApi';
import { FeaturePutRequest } from '../shared/types';
import { editSchema } from './validations';

const EditPage: FC = () => {
  const { id: featureId } = useParams();
  const { data, isFetching } = useGetFeatureQuery(featureId!);
  const [putClub, { isLoading }] = usePutFeatureMutation();
  const navigate = useNavigate();

  useModuleHeader({
    module: {
      title: 'Features',
      name: 'features',
    },
    breadcrumbItems: ['Editar', featureId ? `#${featureId}` : ''],
    actions: [{ name: 'Volver al listado', subModuleTo: 'List' }],
  });

  if (isFetching) return <LoadingPage />;

  const onSave = async (data: FeaturePutRequest) => {
    try {
      if (featureId) {
        await putClub({ featureId: Number(featureId), data }).unwrap();

        showGeneralSuccess().then(() => navigate('/features'));
      }
    } catch (error) {
      const apiError = error as ApiError;
      handleApiError(apiError.data);
      console.error('rejected', error);
    }
  };

  return (
    <FeatureForm
      initialValues={{
        name: data!.name,
        description: data!.description,
        feature_type_id: data!.feature_type_id,
        active: data!.active,
        feature_id: data!.feature_id || undefined,
      }}
      validationSchema={editSchema}
      isSaving={isLoading}
      onSave={onSave}
    />
  );
};

export default EditPage;
