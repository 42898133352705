import cn from 'classnames';
import { FC } from 'react';
import Loading from '../Loading';

interface Props {
  buttonText?: string;
  className?: string;
  loading?: boolean;
  block?: boolean;
  rounded?: boolean;
  btnType: string;
}

const Submit: FC<Props> = ({ buttonText, loading, block, rounded, btnType, className }) => {
  const classes = cn(
    `btn btn-${btnType}`,
    {
      'btn-block': block,
      'btn-rounded': rounded,
    },
    className,
  );
  return (
    <button type="submit" disabled={loading} className={classes}>
      {loading ? <Loading /> : <span>{buttonText || 'Guardar'}</span>}
    </button>
  );
};

export default Submit;
