import { FC } from 'react';

const MenuToggle: FC = () => {
  return (
    <div className="menu-toggle">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default MenuToggle;
