import { Form, Formik } from 'formik';
import ObjectSchema, { ObjectShape } from 'yup/lib/object';
import ButtonSubmit from '../../../components/Buttons/Submit';
import Col from '../../../components/Col';
import Input from '../../../components/Input';
import Row from '../../../components/Row';
import { FeaturePostRequest } from '../shared/types';

interface Props {
  initialValues: FeaturePostRequest;
  validationSchema: ObjectSchema<ObjectShape>;
  onSave: (plan: FeaturePostRequest) => Promise<void>;
  isSaving: boolean;
}

const FeatureForm = ({ initialValues, validationSchema, onSave, isSaving }: Props) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    validateOnChange={false}
    validateOnBlur={false}
    onSubmit={values => onSave(values)}
  >
    <Form className="was-validated" noValidate autoComplete="off">
      <Row>
        <Col sm={6} md={4} xl={3}>
          <Input label="Nombre" name="name" />
        </Col>
        <Col sm={6} md={4} xl={3}>
          <Input label="Descripción" name="description" />
        </Col>
        <Col sm={6} md={4} xl={3}>
          <Input type="number" label="Feature Id (padre)" name="feature_id" />
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col sm={12} md={6} xl={3}>
          <ButtonSubmit
            loading={isSaving}
            btnType="primary"
            className="mt-2"
            block
            rounded
            buttonText="Guardar"
          />
        </Col>
      </Row>
    </Form>
  </Formik>
);

export default FeatureForm;
