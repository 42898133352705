import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { api } from '../services/api';
import moduleReducer from './Module/slice';
import { MODULE_STATE_KEY, USER_STATE_KEY } from './types';
import userReducer from './User/slice';

const persistConfig = {
  key: 'cloud-system-admin',
  version: 1,
  rehydrated: true,
  storage,
};

const persistedReducers = persistReducer(
  persistConfig,
  combineReducers({
    [USER_STATE_KEY]: userReducer,
    [MODULE_STATE_KEY]: moduleReducer,
    [api.reducerPath]: api.reducer,
  }),
);

const store = configureStore({
  reducer: persistedReducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
});

const persistor = persistStore(store);

export function getStore() {
  return store;
}

export function getPersistor() {
  return persistor;
}

export type AppDispatch = typeof store.dispatch;
