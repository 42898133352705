import React from 'react';
import moment from 'moment';

const styles = { fontWeight: 'bold', color: 'white', backgroundColor: 'rgb(39, 35, 58)' };

const Footer: React.FC = () => (
  <>
    <div className="flex-grow-1"></div>
    <div className="app-footer" style={styles}>
      <div className="footer-bottom d-flex justify-content-center flex-column flex-sm-row align-items-center">
        <div className="d-flex align-items-center">
          <div className="text-center">
            <p className="m-0">&copy; {moment().format('YYYY')} Sistema Nube</p>
            <p className="m-0">All rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Footer;
