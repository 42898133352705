import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModuleHeader } from '../../../hooks/useModuleHeader';
import { ApiError } from '../../../services/types';
import { showGeneralSuccess } from '../../../utils/alert';
import { handleApiError } from '../../../utils/errors';
import FeatureForm from '../Components/FeatureForm';
import { usePostFeatureMutation } from '../shared/services/featuresApi';
import { FeaturePostRequest } from '../shared/types';
import { createSchema } from './validations';

const CreatePage: FC = () => {
  useModuleHeader({
    module: {
      title: 'Features',
      name: 'features',
    },
    breadcrumbItems: ['Nuevo'],
    actions: [{ name: 'Volver al listado', subModuleTo: 'List' }],
  });

  const [postPlan, { isLoading }] = usePostFeatureMutation();
  const navigate = useNavigate();

  const onSave = async (data: FeaturePostRequest) => {
    try {
      await postPlan(data).unwrap();
      showGeneralSuccess().then(() => navigate('/features'));
    } catch (error) {
      const apiError = error as ApiError;
      handleApiError(apiError.data);
      console.error('rejected', error);
    }
  };

  return (
    <FeatureForm
      initialValues={{
        name: '',
        description: '',
        feature_type_id: 1,
        active: true,
        feature_id: undefined,
      }}
      validationSchema={createSchema}
      isSaving={isLoading}
      onSave={onSave}
    />
  );
};

export default CreatePage;
