import { useAppSelector } from '../redux/hooks';
import { USER_STATE_KEY } from '../redux/types';

/**
 * useIsAuthenticated
 * @description Get the authentication status from the redux store
 * @returns {boolean} isAuthenticated
 */
export function useIsAuthenticated(): boolean {
  const { user } = useAppSelector(state => ({
    user: state[USER_STATE_KEY].data,
  }));

  return !!user && Object.keys(user).length > 0;
}
