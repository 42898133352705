import React from 'react';
import './styles.css';

interface ContainerProps {
  children: JSX.Element | JSX.Element[];
}

const Container: React.FC<ContainerProps> = ({ children }) => (
  <div className="auth-layout-wrap">
    <div className="auth-content">
      <div className="card o-hidden">
        <div className="row">
          <div className="col-md-12 m-auto">{children}</div>
        </div>
      </div>
    </div>
  </div>
);

export default Container;
