/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { FC, ReactNode } from 'react';
import cn from 'classnames';

type Cols = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface Props {
  children: ReactNode;
  sm?: Cols;
  md?: Cols;
  lg?: Cols;
  xl?: Cols;
  className?: string;
}

const Col: FC<Props> = ({ children, sm, md, lg, xl, className }) => {
  const classes = cn(
    {
      [`col-sm-${sm}`]: sm,
      [`col-md-${md}`]: md,
      [`col-lg-${lg}`]: lg,
      [`col-xl-${xl}`]: xl,
    },
    className,
  );
  return <div className={classes}>{children}</div>;
};

export default Col;
