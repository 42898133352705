import { Form, Formik } from 'formik';
import React from 'react';
import { Navigate } from 'react-router-dom';
import ButtonSubmit from '../../../components/Buttons/Submit';
import Input from '../../../components/Input';
import { useIsAuthenticated } from '../../../hooks/useIsAuthenticated';
import { useAppDispatch } from '../../../redux/hooks';
import { api, useLoginMutation } from '../../../services/api';
import { ApiError, Credentials } from '../../../services/types';
import { handleApiError } from '../../../utils/errors';
import Container from './Components/Container';
import Header from './Components/Header';
import { loginSchema } from './validations';

const Login: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useAppDispatch();
  const [login, { isLoading }] = useLoginMutation();

  if (isAuthenticated) return <Navigate to="/clubs" />;

  const handleLogin = async (values: Credentials) => {
    try {
      await login(values).unwrap();
      dispatch(api.endpoints.whoami.initiate(undefined, { subscribe: false, forceRefetch: true }));
    } catch (error) {
      const apiError = error as ApiError;
      handleApiError(apiError.data);
      console.error('rejected', error);
    }
  };

  return (
    <Container>
      <Header />
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={loginSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={values => handleLogin(values)}
      >
        {() => (
          <Form className="was-validated px-5 pb-4 pt-3" noValidate autoComplete="off">
            <Input label="Email" name="email" />

            <Input label="Contraseña" name="password" type="password" />

            <ButtonSubmit
              loading={isLoading}
              btnType="primary"
              className="mt-5"
              block
              rounded
              buttonText="Ingresar"
            />
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Login;
