import swal from 'sweetalert';

/**
 * askBeforeToAction
 * @description Ask before to action
 * @param {string} text?
 * @returns {Promise<any>}
 */
export const askBeforeToAction = (text?: string): Promise<unknown> => {
  return swal({
    text: text || '¿Está seguro de eliminar este registro?',
    icon: 'warning',
    buttons: ['Cancelar', 'Continuar'],
  });
};

/**
 * showGeneralSuccess
 * @description Show a success message
 * @param {string} message?
 * @returns {any}
 */
export const showGeneralSuccess = (message?: string): Promise<unknown> => {
  return swal({
    title: message ? undefined : 'OK !',
    text: message ? message : 'Se guardó correctamente',
    icon: 'success',
    buttons: ['Cerrar', false],
  });
};

/**
 * showGeneralError
 * @description Show a error message
 * @param {string} errorMessage?
 * @returns {any}
 */
export const showGeneralError = (errorMessage?: string): Promise<unknown> => {
  return swal({
    title: errorMessage ? undefined : 'Ups...',
    text: errorMessage ? errorMessage : 'Ha ocurrido un error',
    icon: 'error',
    buttons: [false, 'OK'],
  });
};
