import { api } from '../../../../services/api';
import { StatusResponse } from '../../../../services/types';
import {
  PlanGet,
  PlanGetResponse,
  PlanPostRequest,
  PlanPutRequest,
  PlansGetResponse,
} from '../types';

export const plansApi = api.injectEndpoints({
  endpoints: builder => ({
    postPlan: builder.mutation<StatusResponse, PlanPostRequest>({
      query: data => ({
        url: '/v1/admin/plans',
        method: 'POST',
        data,
      }),
    }),
    putPlan: builder.mutation<StatusResponse, { planId: number; data: PlanPutRequest }>({
      query: ({ planId, data }) => ({
        url: `/v1/admin/plans/${planId}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (_result, _error, { planId }) => [{ type: 'Plans', id: planId }],
    }),
    getPlans: builder.query<PlansGetResponse, Record<string, string>>({
      query: filters => {
        const queryParams = new URLSearchParams(filters).toString();
        return {
          url: `/v1/admin/plans?${queryParams}`,
        };
      },
    }),
    getPlan: builder.query<PlanGet, string>({
      query: planId => ({
        url: `/v1/admin/plans/${planId}`,
      }),
      providesTags: (_result, _error, planId) => [{ type: 'Plans', id: planId }],
      transformResponse: ({ data }: PlanGetResponse) => data,
    }),
  }),
  overrideExisting: false,
});

export const { useGetPlansQuery, useGetPlanQuery, usePostPlanMutation, usePutPlanMutation } =
  plansApi;
