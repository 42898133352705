import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';

import '@fortawesome/fontawesome-free/js/brands.js';
import '@fortawesome/fontawesome-free/js/fontawesome.js';
import '@fortawesome/fontawesome-free/js/solid.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'jquery';
import './assets/bundle.min.css';
import './assets/sistemanube.css';
import { getPersistor, getStore } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={getStore()}>
      <PersistGate loading={null} persistor={getPersistor()}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
