import { Form, Formik } from 'formik';
import { FC } from 'react';
import ButtonSubmit from '../../../../components/Buttons/Submit';
import Col from '../../../../components/Col';
import InputSearch from '../../../../components/InputSearch';
import Row from '../../../../components/Row';

interface Props {
  loading: boolean;
  onFilter: (filters: Record<string, string>) => void;
}

const FiltersForm: FC<Props> = ({ loading, onFilter }) => {
  return (
    <Formik
      initialValues={{
        search: '',
      }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => onFilter(values)}
    >
      <Form className="was-validated" noValidate autoComplete="off">
        <Row className="justify-content-center">
          <Col sm={12}>
            <InputSearch name="search" placeholder="Buscar" />
          </Col>

          <Col sm={6} className="text-center">
            <ButtonSubmit
              loading={loading}
              btnType="primary"
              className="mt-2"
              block
              rounded
              buttonText="Filtrar"
            />
          </Col>
        </Row>
      </Form>
    </Formik>
  );
};

export default FiltersForm;
