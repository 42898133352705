import { Route } from '../../routes';
import CreatePage from './Create';
import EditPage from './Edit';
import ListPage from './List';

export const clubRoutes: Route[] = [
  {
    name: 'Create',
    path: 'create',
    to: `create`,
    Component: CreatePage,
  },
  {
    name: 'Edit',
    path: 'edit/:id',
    to: `edit`,
    Component: EditPage,
  },
  {
    name: 'List',
    path: '',
    to: '',
    Component: ListPage,
  },
];
