import { useAppSelector } from '../redux/hooks';
import { USER_STATE_KEY } from '../redux/types';

/**
 * useBearerToken
 * @description Get the bearer token from the redux store
 * @returns {(string | undefined)} bearerToken
 */
export function useBearerToken(): string | undefined {
  const { bearerToken } = useAppSelector(state => ({
    bearerToken: state[USER_STATE_KEY].bearerToken,
  }));

  return bearerToken;
}
