import { api } from '../../../../services/api';
import { StatusResponse } from '../../../../services/types';
import {
  FeatureGet,
  FeatureGetResponse,
  FeaturePostRequest,
  FeaturePutRequest,
  FeaturesGetResponse,
} from '../types';

export const featuresApi = api.injectEndpoints({
  endpoints: builder => ({
    postFeature: builder.mutation<StatusResponse, FeaturePostRequest>({
      query: data => ({
        url: '/v1/admin/features',
        method: 'POST',
        data,
      }),
    }),
    putFeature: builder.mutation<StatusResponse, { featureId: number; data: FeaturePutRequest }>({
      query: ({ featureId, data }) => ({
        url: `/v1/admin/features/${featureId}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (_result, _error, { featureId }) => [{ type: 'Features', id: featureId }],
    }),
    getFeatures: builder.query<FeaturesGetResponse, Record<string, string>>({
      query: filters => {
        console.log('filters', filters);
        const queryParams = new URLSearchParams(filters).toString();
        return {
          url: `/v1/admin/features?${queryParams}`,
        };
      },
    }),
    getFeature: builder.query<FeatureGet, string>({
      query: featureId => ({
        url: `/v1/admin/features/${featureId}`,
      }),
      providesTags: (_result, _error, featureId) => [{ type: 'Features', id: featureId }],
      transformResponse: ({ data }: FeatureGetResponse) => data,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetFeaturesQuery,
  useGetFeatureQuery,
  usePostFeatureMutation,
  usePutFeatureMutation,
} = featuresApi;
