import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

const Row: FC<Props> = ({ children, className }) => (
  <div className={`row ${className || ''}`}>{children}</div>
);

export default Row;
