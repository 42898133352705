import { Form, Formik } from 'formik';
import { FC } from 'react';
import ButtonSubmit from '../../../../components/Buttons/Submit';
import Col from '../../../../components/Col';
import Input from '../../../../components/Input';
import InputSearch from '../../../../components/InputSearch';
import Row from '../../../../components/Row';
import PlanFilter from './PlanFilter';
import StateFilter from './StateFilter';

interface Props {
  loading: boolean;
  onFilter: (filters: Record<string, string>) => void;
}

const FiltersForm: FC<Props> = ({ loading, onFilter }) => {
  return (
    <Formik
      initialValues={{
        plan_id: '',
        state_id: '',
        search: '',
        created_at: '',
        trial_expiration_at: '',
      }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => onFilter(values)}
    >
      <Form className="was-validated" noValidate autoComplete="off">
        <Row className="justify-content-center">
          <Col sm={12}>
            <InputSearch name="search" placeholder="Buscar" />
          </Col>
          <Col md={12} xl={3} className="mt-2">
            <PlanFilter />
          </Col>
          <Col md={12} xl={3} className="mt-2">
            <StateFilter />
          </Col>
          <Col md={12} xl={3} className="mt-2">
            <Input type="date" name="created_at" label="Fecha de creación" />
          </Col>
          <Col md={12} xl={3} className="mt-2">
            <Input type="date" name="trial_expiration_at" label="Fecha de expiración" />
          </Col>
          <Col sm={6} className="text-center">
            <ButtonSubmit
              loading={loading}
              btnType="primary"
              className="mt-2"
              block
              rounded
              buttonText="Filtrar"
            />
          </Col>
        </Row>
      </Form>
    </Formik>
  );
};

export default FiltersForm;
