import { FC } from 'react';
import DataTable, { Media, TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import Badge from '../../../components/Badge';
import Col from '../../../components/Col';
import MaterialIconMenu from '../../../components/MaterialIconMenu';
import Row from '../../../components/Row';
import { appConfig } from '../../../config';
import { useDatatable } from '../../../hooks/useDatatable';
import { useModuleHeader } from '../../../hooks/useModuleHeader';
import { useAppDispatch } from '../../../redux/hooks';
import { api } from '../../../services/api';
import { ApiError, ResponseListData } from '../../../services/types';
import { askBeforeToAction, showGeneralSuccess } from '../../../utils/alert';
import { handleApiError } from '../../../utils/errors';
import {
  clubApi,
  useDeleteClubMutation,
  useGetClubsQuery,
  usePatchClubMutation,
} from '../shared/services/clubApi';
import { ClubRow } from '../shared/types';
import FiltersForm from './components/FiltersForm';

const ListPage: FC = () => {
  useModuleHeader({
    module: {
      title: 'Clubs',
      name: 'clubs',
    },
    breadcrumbItems: ['Listado'],
    actions: [{ name: 'Crear', subModuleTo: 'Create', color: 'danger' }],
  });
  const [deleteClub] = useDeleteClubMutation();
  const [patchClub] = usePatchClubMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { clubStates: statesColors } = appConfig.colors;

  const handleDelete = async (club: ClubRow) => {
    await askBeforeToAction(
      `¿Está seguro de eliminar el club "${club.name}"? (Una vez eliminado no se podrá recuperar su información)`,
    ).then(async willContinue => {
      if (willContinue) {
        try {
          await deleteClub(club.id).unwrap();
          showGeneralSuccess(`Club ${club.name} se ha eliminado correctamente`);
          onFilter(filters);
        } catch (error) {
          const apiError = error as ApiError;
          handleApiError(apiError.data);
          console.error('rejected', error);
        }
      }
    });
  };

  const handleChangeState = async (club: ClubRow, stateName: string) => {
    if (club.state.name === stateName) return null;

    const { data: states = [] } = await dispatch(
      api.endpoints.getClubStates.initiate(undefined, { subscribe: true, forceRefetch: true }),
    );

    const activeId = states.find(state => state.name === stateName)?.id;
    if (activeId) {
      try {
        await patchClub({ clubId: club.id, data: { state_id: activeId.toString() } }).unwrap();
        showGeneralSuccess('Estado actualizado');
        onFilter(filters);
      } catch (error) {
        const apiError = error as ApiError;
        handleApiError(apiError.data);
        console.error('rejected', error);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const handleEdit = async (club: ClubRow) => {
    navigate(`/clubs/edit/${club.id}`);
  };

  const handleChangeToActive = async (club: ClubRow) => {
    await handleChangeState(club, 'Activo');
  };

  const handleChangeToInactive = async (club: ClubRow) => {
    await handleChangeState(club, 'Inactivo');
  };

  const handleChangeToPendingPayment = async (club: ClubRow) => {
    await handleChangeState(club, 'Pendiente de pago');
  };

  const columns: TableColumn<ClubRow>[] = [
    {
      name: '#',
      selector: row => row.id,
      maxWidth: '60px',
      minWidth: '30px',
    },
    {
      name: 'Nombre',
      cell: row => (
        <>
          <p className="mb-0 mt-1">
            <a href={String(row.logo)} target="_blank" rel="noreferrer">
              <img src={String(row.logo)} style={{ width: 30 }} />
            </a>
          </p>
          <p className="mb-1">{row.name}</p>
        </>
      ),
      style: {
        flexDirection: 'column',
        whiteSpace: 'nowrap',
      },
    },
    {
      name: 'Subdominio',
      selector: row => row.subdomain,
    },
    {
      name: 'Plan',
      selector: row => row.plan.name,
    },
    {
      name: 'Estado',
      cell: row => {
        const color = statesColors[row.state.name] || 'default';
        return <Badge color={color} text={row.state.name} />;
      },
    },
    {
      name: 'Email Admin',
      selector: row => row.admin,
      minWidth: '300px',
    },
    {
      name: 'Fecha de expiración de prueba',
      cell: row => {
        if (!row.hasTrialTimeActive) return <Badge color="info" text="No aplica" />;

        if (!row.trial_expiration_at)
          return (
            <i
              data-toggle="tooltip"
              title="Cargar Fecha de expiración de prueba"
              className="fas fa-exclamation-triangle text-danger"
            ></i>
          );

        return (
          <p title={`Días restantes: ${row.remainingTrialDays}`} className="mb-0">
            {row.trial_expiration_at}
          </p>
        );
      },
    },
    {
      name: 'Fecha de Alta',
      selector: row => row.created_at,
      hide: Media.LG,
    },
    {
      name: 'Acciones',
      cell: row => {
        let actions = [
          { label: 'Eliminar', onClick: handleDelete },
          { label: 'Editar', onClick: handleEdit },
        ];

        if (row.state.name === 'Activo') {
          actions = [
            ...actions,
            { label: 'Cambiar a Inactivo', onClick: handleChangeToInactive },
            { label: 'Cambiar a Pendiente de Pago', onClick: handleChangeToPendingPayment },
          ];
        } else {
          actions = [...actions, { label: 'Cambiar a Activo', onClick: handleChangeToActive }];
        }

        return <MaterialIconMenu row={row} actions={actions} />;
      },
      maxWidth: '90px',
      minWidth: '30px',
    },
  ];

  const { data, filters, progressPending, onFilter, ...datatableProps } = useDatatable<
    ResponseListData<ClubRow[]>
  >({
    useService: useGetClubsQuery,
    service: clubApi.endpoints.getClubs,
  });

  return (
    <Row>
      <Col lg={12} className="mb-3">
        <div className="card rounded-0">
          <div className="card-body">
            <h4 className="card-title mb-0">Filtros de búsqueda</h4>
            <hr className="my-3" />
            <FiltersForm onFilter={onFilter} loading={progressPending} />
          </div>
        </div>
      </Col>
      <Col lg={12}>
        <DataTable
          columns={columns}
          data={data?.data || []}
          progressPending={progressPending}
          paginationPerPage={data?.meta?.per_page}
          paginationTotalRows={data?.meta?.total}
          {...datatableProps}
          noDataComponent={
            <div className="rdt_NoData">
              <p>No hay resultados encontrados</p>
            </div>
          }
        />
        ;
      </Col>
    </Row>
  );
};

export default ListPage;
