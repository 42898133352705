import { ClubRequest } from '../../shared/types';

export const requestTransformer = ({ club, admin }: ClubRequest): FormData => {
  const formData = new FormData();

  const clubKeys = Object.keys(club) as (keyof typeof club)[];
  for (const clubKey of clubKeys) {
    const value = club[clubKey];
    if (value) {
      formData.append(`club[${clubKey}]`, value instanceof File ? value : String(value));
    }
  }

  const adminKeys = Object.keys(admin) as (keyof typeof admin)[];
  for (const adminKey of adminKeys) {
    const value = admin[adminKey];
    if (value) {
      formData.append(`admin[${adminKey}]`, value);
    }
  }

  return formData;
};
