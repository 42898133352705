import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { MouseEvent, useState } from 'react';

export interface Action<T> {
  label: string;
  onClick: (row: T) => void;
  onlyApplyToOne?: boolean;
}

export interface Props<T> {
  row: T;
  size?: 'small' | 'medium';
  onOpenMenu?: () => void;
  onCloseMenu?: () => void;
  actions: Action<T>[];
}

const MaterialIconMenu = <T,>({ row, onOpenMenu, onCloseMenu, size, actions }: Props<T>) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (onOpenMenu) onOpenMenu();
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onCloseMenu) onCloseMenu();
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size={size}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="menu"
        getContentAnchorEl={null}
        className="material-icon-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions.map((action, idx) => (
          <MenuItem onClick={() => action.onClick(row)} key={idx}>
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MaterialIconMenu;
