import { FC } from 'react';
import defaultUserImg from '../../../../assets/images/default_user.png';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { useAppDispatch } from '../../../../redux/hooks';
import { api } from '../../../../services/api';

const Dropdown: FC = () => {
  const user = useAuthenticatedUser();
  const dispatch = useAppDispatch();
  const getUsername = () => user?.email.split('@')[0] || '';
  const handleLogout = () => {
    dispatch(api.endpoints.logout.initiate());
  };

  return (
    <div className="dropdown">
      <div className="user col align-self-end">
        <img
          src={defaultUserImg}
          id="userDropdown"
          alt=""
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        />
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
          <div className="dropdown-header">
            <i className="i-Lock-User mr-1"></i>
            {getUsername()}
          </div>
          <a className="dropdown-item" onClick={handleLogout} href="#">
            Cerrar Sesión
          </a>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
