import Select from '../../../../../components/Select';
import { useGetClubStatesQuery } from '../../../../../services/api';

const StateFilter = () => {
  const { data = [], isFetching } = useGetClubStatesQuery();
  if (isFetching) return null;

  return (
    <Select name="state_id" label="Filtrar por estado">
      <option value="">Estado</option>
      {data.map(state => (
        <option key={state.id} value={state.id}>
          {state.name}
        </option>
      ))}
    </Select>
  );
};

export default StateFilter;
