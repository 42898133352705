import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingPage from '../../../components/LoadingPage';
import { useModuleHeader } from '../../../hooks/useModuleHeader';
import { ApiError } from '../../../services/types';
import { showGeneralSuccess } from '../../../utils/alert';
import { handleApiError } from '../../../utils/errors';
import ClubForm from '../Components/ClubForm';
import { useGetClubQuery, usePutClubMutation } from '../shared/services/clubApi';
import { ClubRequest } from '../shared/types';
import { editSchema } from './validations';

const EditPage: FC = () => {
  const { id: clubId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data, isFetching } = useGetClubQuery(clubId!);
  const [putClub, { isLoading }] = usePutClubMutation();
  const navigate = useNavigate();

  useModuleHeader({
    module: {
      title: 'Clubs',
      name: 'clubs',
    },
    breadcrumbItems: ['Editar', clubId ? `#${clubId}` : ''],
    actions: [{ name: 'Volver al listado', subModuleTo: 'List' }],
  });

  if (isFetching) return <LoadingPage />;

  const onSave = async (club: ClubRequest) => {
    try {
      if (clubId) {
        await putClub({ clubId: Number(clubId), data: club }).unwrap();
        showGeneralSuccess().then(() => navigate('/clubs'));
      }
    } catch (error) {
      const apiError = error as ApiError;
      handleApiError(apiError.data);
      console.error('rejected', error);
    }
  };

  return (
    <ClubForm
      initialValues={{
        club: {
          name: data?.club.name || '',
          subdomain: data?.club.subdomain || '',
          logo: '',
          card: '',
          icon: '',
          parking_domain: data?.club.parking_domain || '',
          plan_id: data?.club.plan_id || '',
          state_id: data?.club.state_id || '',
          trial_expiration_at: data?.club.trial_expiration_at || '',
        },
        admin: {
          email: data?.admin.email || '',
          password: '',
        },
      }}
      validationSchema={editSchema}
      isSaving={isLoading}
      onSave={onSave}
    />
  );
};

export default EditPage;
