import React from 'react';
import { systemModules } from '../../pages/routes';
import PrimaryMenu from './PrimaryMenu';

const SidebarPage: React.FC = () => (
  <div className="side-content-wrap">
    <PrimaryMenu routes={Object.values(systemModules)} />
    <div className="sidebar-overlay"></div>
  </div>
);

export default SidebarPage;
