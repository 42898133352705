import { FC } from 'react';

interface Props {
  type: 'valid' | 'invalid';
  message: string;
}

const Feedback: FC<Props> = ({ type, message }) => (
  <div className={`${type}-feedback`}>{message}</div>
);

export default Feedback;
