import axios, { AxiosRequestTransformer } from 'axios';
import { api } from '../../../../services/api';
import { ResponseData, ResponseListData, StatusResponse } from '../../../../services/types';
import { Club, ClubRequest, ClubRow } from '../types';
import { requestTransformer } from './request-transformer';

export const clubApi = api.injectEndpoints({
  endpoints: builder => ({
    deleteClub: builder.mutation<StatusResponse, number>({
      query: clubId => ({
        url: `/v1/admin/clubs/${clubId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, clubId) => [{ type: 'Clubs', id: clubId }],
    }),
    patchClub: builder.mutation<StatusResponse, { clubId: number; data: Partial<Club> }>({
      query: ({ clubId, data }) => ({
        url: `/v1/admin/clubs/${clubId}`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (_result, _error, { clubId }) => [{ type: 'Clubs', id: clubId }],
    }),
    postClub: builder.mutation<StatusResponse, ClubRequest>({
      query: data => ({
        url: '/v1/admin/clubs',
        method: 'POST',
        data,
        transformRequest: [
          requestTransformer,
          ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
        ],
      }),
    }),
    putClub: builder.mutation<StatusResponse, { clubId: number; data: ClubRequest }>({
      query: ({ clubId, data }) => ({
        url: `/v1/admin/clubs/${clubId}`,
        method: 'POST',
        data,
        transformRequest: [
          requestTransformer,
          ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
        ],
      }),
      invalidatesTags: (_result, _error, { clubId }) => [{ type: 'Clubs', id: clubId }],
    }),
    getClubs: builder.query<ResponseListData<ClubRow[]>, Record<string, string>>({
      query: filters => {
        const queryParams = new URLSearchParams(filters).toString();
        return {
          url: `/v1/admin/clubs?${queryParams}`,
        };
      },
    }),
    getClub: builder.query<ClubRequest, string>({
      query: clubId => ({
        url: `/v1/admin/clubs/${clubId}`,
      }),
      providesTags: (_result, _error, clubId) => [{ type: 'Clubs', id: clubId }],
      transformResponse: ({ data }: ResponseData<ClubRequest>) => data,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetClubsQuery,
  useGetClubQuery,
  usePostClubMutation,
  usePatchClubMutation,
  usePutClubMutation,
  useDeleteClubMutation,
} = clubApi;
