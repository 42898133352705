import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModuleHeader } from '../../../hooks/useModuleHeader';
import { ApiError } from '../../../services/types';
import { showGeneralSuccess } from '../../../utils/alert';
import { handleApiError } from '../../../utils/errors';
import PlanForm, { InitialPlan } from '../Components/PlanForm';
import { usePostPlanMutation } from '../shared/services/plansApi';
import { createSchema } from './validations';

const CreatePage: FC = () => {
  useModuleHeader({
    module: {
      title: 'Planes',
      name: 'plans',
    },
    breadcrumbItems: ['Nuevo'],
    actions: [{ name: 'Volver al listado', subModuleTo: 'List' }],
  });

  const [postPlan, { isLoading }] = usePostPlanMutation();
  const navigate = useNavigate();

  const onSave = async (plan: InitialPlan) => {
    try {
      const data = {
        ...plan,
        features: Object.keys(plan.features).filter(key => plan.features[key]),
      };

      await postPlan(data).unwrap();
      showGeneralSuccess().then(() => navigate('/plans'));
    } catch (error) {
      const apiError = error as ApiError;
      handleApiError(apiError.data);
      console.error('rejected', error);
    }
  };

  return (
    <PlanForm
      initialValues={{
        name: '',
        currency: 'ARS',
        price: 1,
        is_plan_test: false,
        test_days: 0,
        features: {},
      }}
      validationSchema={createSchema}
      isSaving={isLoading}
      onSave={onSave}
    />
  );
};

export default CreatePage;
