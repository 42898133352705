import { useLayoutEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useBearerToken } from '../../hooks/useBearerToken';
import { useIsAuthenticated } from '../../hooks/useIsAuthenticated';
import useModules from '../../hooks/useModules';
import { useAppDispatch } from '../../redux/hooks';
import { expiredToken } from '../../redux/User/actions';
import { isExpiredToken } from '../../utils/token';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import {
  disconnectEvents,
  loadDropdown,
  loadLayoutPlugins,
  loadPerfectScroll,
  loadTooltips,
} from './scripts';

const Layout = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const bearerToken = useBearerToken();

  const { availableRoutes } = useModules();

  useLayoutEffect(() => {
    loadTooltips();
    loadPerfectScroll();
    loadDropdown();
    loadLayoutPlugins();

    return () => disconnectEvents();
  }, []);

  if (!isAuthenticated || !bearerToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (isExpiredToken(bearerToken)) {
    dispatch(expiredToken());
    return null;
  }

  return (
    <div className="app-admin-wrap layout-sidebar-large">
      <Header />
      {<Sidebar />}
      <div className="main-content-wrap sidenav-open d-flex flex-column">
        <Routes>
          {availableRoutes.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}

          {/* En caso de no encontrar una ruta, por default se redirige al dashboard */}
          {/* <Route path="/*" element={<Navigate to="/dashboard" />} /> */}
        </Routes>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
