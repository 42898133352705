import Select from '../../../../../components/Select';
import { useGetAllPlansQuery } from '../../../../../services/api';

const PlanFilter = () => {
  const { data = [], isFetching } = useGetAllPlansQuery();
  if (isFetching) return null;

  return (
    <Select name="plan_id" label="Filtrar por plan">
      <option value="">Plan</option>
      {data.map(plan => (
        <option key={plan.id} value={plan.id}>
          {plan.name}
        </option>
      ))}
    </Select>
  );
};

export default PlanFilter;
