import { ErrorMessage, useField } from 'formik';
import { FC } from 'react';
import Feedback from '../Feedback/index';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  extraInfo?: string;
  multiple?: boolean;
  handleChange: (field: string, value: File | FileList) => void;
  [x: string]: unknown;
}

const InputFile: FC<Props> = ({ label, extraInfo, handleChange, ...props }) => {
  const [field, meta] = useField<File>(props);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange, value, ...restInputProps } = field;

  return (
    <div className="form-group">
      <label htmlFor={props.id || props.name}>
        {label}{' '}
        {extraInfo && (
          <i data-toggle="tooltip" title={extraInfo} className="fas fa-question-circle"></i>
        )}
      </label>
      <input
        id={props.id || props.name}
        className={`form-control form-control-rounded ${
          meta.error && meta.touched ? 'is-invalid' : ''
        }`}
        type="file"
        {...restInputProps}
        {...props}
        onChange={e => {
          const files = e.target.files;

          if (files && files.length > 0) {
            handleChange(props.name, props.multiple ? files : files?.[0]);
          }
        }}
      />
      <ErrorMessage
        name={props.name}
        render={message => <Feedback type="invalid" message={message} />}
      />
    </div>
  );
};

export default InputFile;
