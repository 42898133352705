import { ErrorResponse } from '../services/types';
import { showGeneralError } from './alert';

/**
 * handleApiError
 * @description Handle an error from the API
 * @param {ErrorResponse} {errors}
 * @returns {any}
 */
export const handleApiError = ({ errors }: ErrorResponse): void => {
  const errorMesages = Object.values(errors);

  showGeneralError(errorMesages.join('\n'));
};
