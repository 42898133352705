import { ExoticComponent, FC, LazyExoticComponent } from 'react';
import Layout from '../components/Layout';
import LoginPage from '../pages/Auth/Login';

type JSXComponent = () => JSX.Element;

export interface Route {
  to: string;
  path: string;
  icon?: string;
  Component: LazyExoticComponent<JSXComponent> | JSXComponent | ExoticComponent | FC;
  name: string;
  exact?: boolean;
  requirePermission?: string;
}

export interface RouteWithSubmodules extends Route {
  subModules: Route[];
}

export interface SystemModules {
  clubs: RouteWithSubmodules;
  plans: RouteWithSubmodules;
  features: RouteWithSubmodules;
}

export const routes: Route[] = [
  {
    to: '/login',
    path: 'login',
    Component: LoginPage,
    name: 'Login',
  },
  {
    to: '',
    path: '/*',
    Component: Layout,
    name: 'Layout',
  },
];
