import * as yup from 'yup';

const clubSchema = yup.object().shape({
  name: yup.string().required('Es requerido'),
  subdomain: yup.string().required('Es requerido'),
  logo: yup.string().optional(),
  icon: yup.string().optional(),
  card: yup.string().optional(),
  plan_id: yup.string().required('Es requerido'),
  state_id: yup.string().required('Es requerido'),
  parking_domain: yup.string().optional(),
  trial_expiration_at: yup.string().optional(),
});

const adminSchema = yup.object({
  email: yup.string().required('Es requerido'),
  password: yup.string().optional(),
});

export const editSchema = yup.object().shape({
  club: clubSchema,
  admin: adminSchema,
});
