import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/newLogoNube.png';
import Dropdown from './Components/Dropdown';
import MenuToggle from './Components/MenuToggle';

const Header: React.FC = () => {
  return (
    <div className="main-header" style={{ backgroundColor: '#27233a' }}>
      <MenuToggle />

      <div className="cloud-system-logo">
        <NavLink to="/dashboard">
          <img src={Logo} alt="" />
        </NavLink>
      </div>

      <div className="header-part-right">
        <Dropdown />
      </div>
    </div>
  );
};

export default Header;
