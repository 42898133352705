import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingPage from '../../../components/LoadingPage';
import { useModuleHeader } from '../../../hooks/useModuleHeader';
import { ApiError } from '../../../services/types';
import { showGeneralSuccess } from '../../../utils/alert';
import { handleApiError } from '../../../utils/errors';
import PlanForm, { InitialPlan } from '../Components/PlanForm';
import { useGetPlanQuery, usePutPlanMutation } from '../shared/services/plansApi';
import { editSchema } from './validations';

const EditPage: FC = () => {
  const { id: planId } = useParams();
  const { data, isFetching } = useGetPlanQuery(planId!);
  const [putClub, { isLoading }] = usePutPlanMutation();
  const navigate = useNavigate();

  useModuleHeader({
    module: {
      title: 'Planes',
      name: 'plans',
    },
    breadcrumbItems: ['Editar', planId ? `#${planId}` : ''],
    actions: [{ name: 'Volver al listado', subModuleTo: 'List' }],
  });

  if (isFetching) return <LoadingPage />;

  const onSave = async (plan: InitialPlan) => {
    try {
      if (planId) {
        const data = {
          ...plan,
          features: Object.keys(plan.features).filter(key => plan.features[key]),
        };

        await putClub({ planId: Number(planId), data }).unwrap();
        showGeneralSuccess().then(() => navigate('/plans'));
      }
    } catch (error) {
      const apiError = error as ApiError;
      handleApiError(apiError.data);
      console.error('rejected', error);
    }
  };

  return (
    <PlanForm
      initialValues={{
        name: data!.name,
        currency: data!.currency,
        price: data!.price,
        is_plan_test: data!.is_plan_test,
        test_days: data!.test_days,
        features: data!.features.reduce((acc, feature) => ({ ...acc, [feature]: true }), {}),
      }}
      validationSchema={editSchema}
      isSaving={isLoading}
      onSave={onSave}
    />
  );
};

export default EditPage;
