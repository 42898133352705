import { ErrorMessage, useField } from 'formik';
import { FC } from 'react';
import Feedback from '../Feedback/index';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  type?: 'text' | 'email' | 'password' | 'date' | 'number' | 'tel';
  placeholder?: string;
  extraInfo?: string;
  [x: string]: unknown;
}

const Input: FC<Props> = ({ label, extraInfo, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="form-group">
      <label htmlFor={props.id || props.name}>
        {label}{' '}
        {extraInfo && (
          <i data-toggle="tooltip" title={extraInfo} className="fas fa-question-circle"></i>
        )}
      </label>
      <input
        className={`form-control form-control-rounded ${
          meta.error && meta.touched ? 'is-invalid' : ''
        }`}
        id={props.id || props.name}
        {...field}
        {...props}
      />
      <ErrorMessage name={props.name}>
        {message => <Feedback type="invalid" message={message} />}
      </ErrorMessage>
    </div>
  );
};

export default Input;
