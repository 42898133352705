import React, { ReactNode } from 'react';
import { Color } from '../Module/Header/ButtonAction';

export interface Props {
  color: Color;
  text?: string;
  size?: number;
  className?: string;
  children?: ReactNode;
}

const Badge: React.FC<Props> = ({ color, text, size = 2, className, children }) => (
  <p className={`badge badge-${color} p-${size} mb-0 ${className || ''}`}>{children || text}</p>
);

export default Badge;
