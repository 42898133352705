import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../services/api';
import { AuthenticatedUser } from '../../services/types';
import { USER_STATE_KEY } from '../types';
import { expiredToken } from './actions';

export interface UserState {
  data: AuthenticatedUser | null;
  bearerToken?: string;
}

const initialState = { data: null } as UserState;

export const userSlice = createSlice({
  name: USER_STATE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(expiredToken, state => {
      state.data = null;
      state.bearerToken = undefined;
    });

    builder.addMatcher(api.endpoints.whoami.matchFulfilled, (state, action) => {
      state.data = action.payload.data;
    });

    builder.addMatcher(api.endpoints.logout.matchFulfilled, state => {
      state.data = null;
      state.bearerToken = undefined;
    });

    builder.addMatcher(api.endpoints.login.matchFulfilled, (state, action) => {
      state.bearerToken = action.payload.access_token;
    });
  },
});

export default userSlice.reducer;
