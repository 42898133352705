import React from 'react';
import Logo from '../../../../../assets/images/logo-full.png';

const styles = {
  backgroundColor: 'rgb(39, 35, 59)',
};

const Header: React.FC = () => (
  <div className="text-center mb-4 p-5" style={styles}>
    <img className="w-75" src={Logo} alt="Logo" />
  </div>
);

export default Header;
