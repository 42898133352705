import { useEffect } from 'react';
import { systemModules } from '../pages/routes';
import { useAppDispatch } from '../redux/hooks';
import { setHeader } from '../redux/Module/actions';
import { SystemModules } from '../routes';
import { Color } from '../components/Module/Header/ButtonAction';

interface Action {
  name: string;
  subModuleTo: string;
  color?: Color;
}

interface Module {
  title: string;
  name: keyof SystemModules;
}

interface Props {
  module: Module;
  breadcrumbItems?: string[];
  actions: Action[];
}

export function useModuleHeader({ module, actions, breadcrumbItems }: Props) {
  const dispatch = useAppDispatch();
  const { name: moduleName, title: moduleTitle } = module;
  const { subModules } = systemModules[moduleName];

  useEffect(() => {
    dispatch(
      setHeader({
        title: moduleTitle,
        breadcrumbItems,
        buttonActions: actions.map(({ name, color, subModuleTo }) => ({
          name,
          color,
          pathTo:
            subModules.find(subModule => subModule.name === subModuleTo)?.to ||
            systemModules[moduleName].to,
        })),
      }),
    );
  }, []);
}
