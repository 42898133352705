import { ErrorMessage, useField } from 'formik';
import { FC } from 'react';
import Feedback from '../Feedback/index';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  type?: 'checkbox';
  name: string;
  extraInfo?: string;
  extraLabel?: string;
  onChange?: (() => Promise<void>) | (() => void);
  className?: string;
  [x: string]: unknown;
}

const InputSwitch: FC<Props> = ({
  label,
  className,
  extraInfo,
  extraLabel,
  onChange,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <div className={`form-group ${className || ''}`}>
      <label
        className="mb-0"
        htmlFor={props.id || props.name}
        style={{ verticalAlign: 'text-bottom' }}
      >
        {props.required && (
          <span className="text-danger">
            <b>*</b>{' '}
          </span>
        )}
        {label}{' '}
        {extraInfo && (
          <i data-toggle="tooltip" title={extraInfo} className="fas fa-question-circle"></i>
        )}
      </label>
      <label className="switch switch-success mb-0 float-right">
        <input
          id={props.id || props.name}
          {...field}
          {...props}
          type="checkbox"
          className={`form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            field.onChange(e);

            if (onChange) {
              onChange();
            }
          }}
        />
        <span className="slider"></span>
      </label>
      {extraLabel && (
        <label className="extra-label" htmlFor={props.id || props.name}>
          <i className="simple-icon-info"></i> {extraLabel}
        </label>
      )}
      <ErrorMessage name={props.name}>
        {message => <Feedback type="invalid" message={message} />}
      </ErrorMessage>
    </div>
  );
};

export default InputSwitch;
