import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModuleHeader } from '../../../hooks/useModuleHeader';
import { ApiError } from '../../../services/types';
import { showGeneralSuccess } from '../../../utils/alert';
import { handleApiError } from '../../../utils/errors';
import ClubForm from '../Components/ClubForm';
import { usePostClubMutation } from '../shared/services/clubApi';
import { ClubRequest } from '../shared/types';
import { createSchema } from './validations';

const CreatePage: FC = () => {
  useModuleHeader({
    module: {
      title: 'Clubs',
      name: 'clubs',
    },
    breadcrumbItems: ['Nuevo'],
    actions: [{ name: 'Volver al listado', subModuleTo: 'List' }],
  });

  const [postClub, { isLoading }] = usePostClubMutation();
  const navigate = useNavigate();

  const onSave = async (club: ClubRequest) => {
    try {
      await postClub(club).unwrap();
      showGeneralSuccess().then(() => navigate('/clubs'));
    } catch (error) {
      const apiError = error as ApiError;
      handleApiError(apiError.data);
      console.error('rejected', error);
    }
  };

  return (
    <ClubForm
      initialValues={{
        club: {
          name: '',
          subdomain: '',
          logo: '',
          card: '',
          icon: '',
          parking_domain: '',
          trial_expiration_at: '',
          plan_id: '',
          state_id: '',
        },
        admin: {
          email: '',
          password: '',
        },
      }}
      validationSchema={createSchema}
      isSaving={isLoading}
      onSave={onSave}
    />
  );
};

export default CreatePage;
