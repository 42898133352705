import { lazy } from 'react';
import { SystemModules } from '../routes';
import { clubRoutes } from './Clubs/routes';
import { featuresRoutes } from './Features/routes';
import { plansRoutes } from './Plans/routes';

const LazyClubModule = lazy(() => import(/* webpackChunkName: "ClubModule" */ './Clubs'));
const LazyPlanModule = lazy(() => import(/* webpackChunkName: "PlanModule" */ './Plans'));
const LazyFeatureModule = lazy(() => import(/* webpackChunkName: "FeatureModule" */ './Features'));

export const systemModules: SystemModules = {
  clubs: {
    name: 'Clubs',
    icon: 'fas fa-gifts',
    path: '/clubs/*',
    to: '/clubs',
    Component: LazyClubModule,
    subModules: clubRoutes,
  },
  plans: {
    name: 'Planes',
    icon: 'fas fa-envelope',
    path: '/plans/*',
    to: '/plans',
    Component: LazyPlanModule,
    subModules: plansRoutes,
  },
  features: {
    name: 'Features',
    icon: 'fas fa-cogs',
    path: '/features/*',
    to: '/features',
    Component: LazyFeatureModule,
    subModules: featuresRoutes,
  },
};
