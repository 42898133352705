import { FC } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import Col from '../../../components/Col';
import MaterialIconMenu from '../../../components/MaterialIconMenu';
import Row from '../../../components/Row';
import { useDatatable } from '../../../hooks/useDatatable';
import { useModuleHeader } from '../../../hooks/useModuleHeader';
import { featuresApi, useGetFeaturesQuery } from '../shared/services/featuresApi';
import { FeatureGet, FeaturesGetResponse } from '../shared/types';
import FiltersForm from './components/FiltersForm';

const ListPage: FC = () => {
  useModuleHeader({
    module: {
      title: 'Features',
      name: 'features',
    },
    breadcrumbItems: ['Listado'],
    actions: [{ name: 'Crear', subModuleTo: 'Create', color: 'danger' }],
  });

  const navigate = useNavigate();

  const handleEdit = (feature: FeatureGet) => {
    navigate(`/features/edit/${feature.id}`);
  };

  const columns: TableColumn<FeatureGet>[] = [
    {
      name: '#',
      selector: row => row.id,
      maxWidth: '60px',
      minWidth: '30px',
    },
    {
      name: 'Nombre',
      selector: row => row.name,
    },
    {
      name: 'Descripción',
      selector: row => row.description,
    },
    {
      name: 'Acciones',
      cell: row => {
        const actions = [{ label: 'Editar', onClick: handleEdit }];

        return <MaterialIconMenu row={row} actions={actions} />;
      },
      maxWidth: '90px',
      minWidth: '30px',
    },
  ];

  const { data, progressPending, onFilter, ...datatableProps } = useDatatable<FeaturesGetResponse>({
    useService: useGetFeaturesQuery,
    service: featuresApi.endpoints.getFeatures,
  });

  return (
    <Row>
      <Col lg={12} className="mb-3">
        <div className="card rounded-0">
          <div className="card-body">
            <h4 className="card-title mb-0">Filtros de búsqueda</h4>
            <hr className="my-3" />
            <FiltersForm onFilter={onFilter} loading={progressPending} />
          </div>
        </div>
      </Col>
      <Col lg={12}>
        <DataTable
          columns={columns}
          data={data?.data || []}
          progressPending={progressPending}
          paginationPerPage={data?.meta?.per_page}
          paginationTotalRows={data?.meta?.total}
          {...datatableProps}
          noDataComponent={
            <div className="rdt_NoData">
              <p>No hay resultados encontrados</p>
            </div>
          }
        />
        ;
      </Col>
    </Row>
  );
};

export default ListPage;
