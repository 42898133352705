import { useAppSelector } from '../redux/hooks';
import { USER_STATE_KEY } from '../redux/types';
import { AuthenticatedUser } from '../services/types';

/**
 * useAuthenticatedUser
 * @description Get the authenticated user from the redux store
 * @returns {(AuthenticatedUser | null)} user
 */
export function useAuthenticatedUser(): AuthenticatedUser | null {
  const { user } = useAppSelector(state => ({
    user: state[USER_STATE_KEY].data,
  }));

  return user || null;
}
