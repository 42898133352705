import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import { JWTDecoded } from '../services/types';

/**
 * isExpiredToken
 * @description Check if token is expired
 * @param {string} token
 * @returns {boolean}
 */
export const isExpiredToken = (accessToken: string): boolean => {
  if (!accessToken) return true;

  const tokenDecoded: JWTDecoded = jwtDecode(accessToken);
  return dayjs.unix(tokenDecoded.exp).diff(dayjs()) < 1;
};
