import { ErrorMessage, useField } from 'formik';
import { FC } from 'react';
import Feedback from '../Feedback/index';

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  name: string;
  extraInfo?: string;
  className?: string;
  [x: string]: unknown;
}

const Select: FC<Props> = ({ label, extraInfo, className, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className={`form-group ${className || ''}`}>
      <label htmlFor={props.id || props.name}>
        {label}{' '}
        {extraInfo && (
          <i data-toggle="tooltip" title={extraInfo} className="fas fa-question-circle"></i>
        )}
      </label>
      <select
        id={props.id || props.name}
        className={`form-control form-control-rounded ${
          meta.error && meta.touched ? 'is-invalid' : ''
        }`}
        {...field}
        {...props}
      />
      <ErrorMessage
        name={props.name}
        render={message => <Feedback type="invalid" message={message} />}
      />
    </div>
  );
};

export default Select;
