import { FC } from 'react';

const loadingStyles: React.CSSProperties = {
  position: 'fixed',
  top: '45%',
  left: '0%',
  width: '100%',
  fontSize: 10,
};

const LoadingPage: FC = () => (
  <div className="row" style={loadingStyles}>
    <div className="loader-bubble loader-bubble-info m-6"></div>
  </div>
);

export default LoadingPage;
