import { Reducer } from '@reduxjs/toolkit';
import { ModuleState } from './Module/slice';
import { UserState } from './User/slice';

export const USER_STATE_KEY = 'user';
export const MODULE_STATE_KEY = 'module';

export type RootState = {
  [USER_STATE_KEY]: UserState;
  [MODULE_STATE_KEY]: ModuleState;
};

export type StoreShape = Partial<RootState>;

export type StateKey = keyof StoreShape;

export type ReducerMap = Partial<{ [k in StateKey]: Reducer<RootState[k]> }>;
