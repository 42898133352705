import React from 'react';
import { Route } from '../../../routes';
import PrimaryItem from './Components/PrimaryItem';

interface Props {
  routes: Route[];
}

const PrimaryMenu: React.FC<Props> = ({ routes }) => (
  <div
    style={{ backgroundColor: '#ffffff' }}
    className="sidebar-left open rtl-ps-none"
    data-perfect-scrollbar=""
    data-suppress-scroll-x="true"
  >
    <ul className="navigation-left">
      {routes.map((route: Route, key: number) => (
        <PrimaryItem key={key} route={route} />
      ))}
    </ul>
  </div>
);
export default PrimaryMenu;
