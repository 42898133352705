import { Form, Formik } from 'formik';
import ObjectSchema, { ObjectShape } from 'yup/lib/object';
import ButtonSubmit from '../../../components/Buttons/Submit';
import Col from '../../../components/Col';
import Input from '../../../components/Input';
import InputFile from '../../../components/InputFile';
import Row from '../../../components/Row';
import Select from '../../../components/Select';
import { useGetAllPlansQuery, useGetClubStatesQuery } from '../../../services/api';
import { ClubRequest } from '../shared/types';

interface Props {
  initialValues: ClubRequest;
  validationSchema: ObjectSchema<ObjectShape>;
  onSave: (club: ClubRequest) => Promise<void>;
  isSaving: boolean;
}

const ClubForm = ({ initialValues, validationSchema, onSave, isSaving }: Props) => {
  const { data: plans = [] } = useGetAllPlansQuery();
  const { data: states = [] } = useGetClubStatesQuery();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => onSave(values)}
    >
      {({ setFieldValue }) => (
        <Form className="was-validated" noValidate autoComplete="off">
          <Row>
            <Col sm={6} md={4} xl={3}>
              <Input label="Nombre" name="club.name" />
            </Col>

            <Col sm={6} md={4} xl={3}>
              <Input label="Subdominio" name="club.subdomain" />
            </Col>

            <Col sm={6} md={4} xl={3}>
              <Input label="Dominio Parqueado" name="club.parking_domain" />
            </Col>

            <Col sm={6} md={4} xl={3}>
              <Input
                label="Fecha de expiración de prueba"
                name="club.trial_expiration_at"
                extraInfo="Para que tenga efecto esta fecha el club debe tener seleccionado el plan Demo"
                type="date"
              />
            </Col>

            <Col sm={6} md={4} xl={3}>
              <Select name="club.plan_id" label="Plan">
                <option value=""> Elegir </option>
                {plans.map(plan => (
                  <option key={plan.id} value={plan.id}>
                    {plan.name}
                  </option>
                ))}
              </Select>
            </Col>

            <Col sm={6} md={4} xl={3}>
              <Select name="club.state_id" label="Estado">
                <option value=""> Elegir </option>
                {states.map(state => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
              </Select>
            </Col>

            <Col sm={6} md={4} xl={3}>
              <InputFile
                label="Icono para PWA"
                accept="image/*"
                extraInfo="Tamaño: 512x512. Formatos aceptados: .png"
                name="club.icon"
                handleChange={setFieldValue}
              />
            </Col>

            <Col sm={6} md={4} xl={3}>
              <InputFile
                label="Logo"
                accept="image/*"
                extraInfo="Formatos aceptados: .png, .jpg, .jpeg"
                name="club.logo"
                handleChange={setFieldValue}
              />
            </Col>

            <Col sm={6} md={4} xl={3}>
              <InputFile
                label="Tarjeta"
                accept="image/*"
                extraInfo="Formatos aceptados: .png, .jpg, .jpeg"
                name="club.card"
                handleChange={setFieldValue}
              />
            </Col>

            <Col sm={6} md={4} xl={3}>
              <Input label="Email del Admin" name="admin.email" />
            </Col>

            <Col sm={6} md={4} xl={3}>
              <Input label="Password del Admin" name="admin.password" type="password" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={12} md={6} xl={3}>
              <ButtonSubmit
                loading={isSaving}
                btnType="primary"
                className="mt-2"
                block
                rounded
                buttonText="Guardar"
              />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default ClubForm;
