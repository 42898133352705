import React from 'react';

const Loading: React.FC = () => (
  <div
    style={{ fontSize: 4, top: -9 }}
    className="loader-bubble text-white loader-bubble-info"
  ></div>
);

export default Loading;
