/* eslint-disable @typescript-eslint/no-explicit-any */
import $ from 'jquery';
import PerfectScrollbar from 'perfect-scrollbar';
import { isMobileOnly } from 'react-device-detect';

export const loadTooltips = (): void => {
  $('[data-toggle=tooltip]').tooltip();
};

export const loadPerfectScroll = (): void => {
  $('.perfect-scrollbar, [data-perfect-scrollbar]').each(function () {
    const $el = $(this);
    new PerfectScrollbar(this, {
      suppressScrollX: $el.data('suppress-scroll-x') as boolean,
      suppressScrollY: $el.data('suppress-scroll-y') as boolean,
    });
  });
};

export const loadDropdown = (): void => {
  const $dropdown = $('.dropdown-sidemenu');
  $dropdown.find('> a').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    const $parent = $(this).parent('.dropdown-sidemenu');
    $dropdown.not($parent).removeClass('open');
    $(this).parent('.dropdown-sidemenu').toggleClass('open');
  });
};

function openSidebar() {
  $('.sidebar-left').addClass('open');
  $('.main-content-wrap').addClass('sidenav-open');
}

function closeSidebar() {
  $('.sidebar-left').removeClass('open');
  $('.main-content-wrap').removeClass('sidenav-open');
}

function openSidebarSecondary() {
  $('.sidebar-left-secondary').addClass('open');
  $('.sidebar-overlay').addClass('open');
}

function closeSidebarSecondary() {
  $('.sidebar-left-secondary').removeClass('open');
  $('.sidebar-overlay').removeClass('open');
}

function navItemToggleActive($activeItem: JQuery<HTMLElement>) {
  const $navItem = $('.nav-item');
  $navItem.removeClass('active');
  $activeItem.addClass('active');
}

function initLayout() {
  // Makes secondary menu selected on page load
  $('.nav-item').each(function () {
    const $item = $(this);

    if ($item.hasClass('active')) {
      const dataItem = $item.data('item') as string;
      $('.sidebar-left-secondary').find('[data-parent="'.concat(dataItem, '"]')).show();
    }
  });

  if (isMobileOnly) {
    closeSidebar();
    closeSidebarSecondary();
  }
}

export const loadLayoutPlugins = (): void => {
  initLayout();

  $(window).on('resize', function () {
    if (isMobileOnly) {
      closeSidebar();
      closeSidebarSecondary();
    }
  });

  $('.sidebar-left')
    .find('.nav-item')
    .on('mouseenter', function (event) {
      const $navItem = $(event.currentTarget);
      const dataItem = $navItem.data('item') as string;

      if (dataItem) {
        navItemToggleActive($navItem);
        openSidebarSecondary();
      } else {
        closeSidebarSecondary();
      }

      $('.sidebar-left-secondary').find('.childNav').hide();
      $('.sidebar-left-secondary').find('[data-parent="'.concat(dataItem, '"]')).show();
    });

  $('.sidebar-left')
    .find('.nav-item')
    .on('click', function (e) {
      const $navItem = $(e.currentTarget);
      const dataItem = $navItem.data('item') as string;

      if (dataItem) {
        e.preventDefault();
      }
    });

  $('.sidebar-overlay').on('click', function () {
    if (isMobileOnly) {
      closeSidebar();
    }

    closeSidebarSecondary();
  });

  $('.menu-toggle')
    .off()
    .on('click', function () {
      const isSidebarOpen = $('.sidebar-left').hasClass('open');
      const isSidebarSecOpen = $('.sidebar-left-secondary').hasClass('open');
      const dataItem = $('.nav-item.active').data('item') as string;

      if (isSidebarOpen && isSidebarSecOpen && isMobileOnly) {
        closeSidebar();
        closeSidebarSecondary();
      } else if (isSidebarOpen && isSidebarSecOpen) {
        closeSidebarSecondary();
      } else if (isSidebarOpen) {
        closeSidebar();
      } else if (!isSidebarOpen && !isSidebarSecOpen && !dataItem) {
        openSidebar();
      } else if (!isSidebarOpen && !isSidebarSecOpen) {
        openSidebar();
        openSidebarSecondary();
      }
    });
};

export const disconnectEvents = (): void => {
  $('.menu-toggle').off();
  $('.sidebar-overlay').off();
  $('.sidebar-left').off();
};
