import { Route } from '../routes';
import { systemModules } from '../pages/routes';

interface ModuleUtils {
  availableRoutes: Route[];
}

export default function useModules(): ModuleUtils {
  //TODO: Agregar lógica de chequeo de permisos para habilitar las routes
  const availableRoutes = [systemModules.clubs, systemModules.plans, systemModules.features];

  return { availableRoutes };
}
