import { FC } from 'react';
import InputSwitch from '../../../components/InputSwitch';
import { GroupFeature } from '../../../services/types';

interface Props {
  groupFeature: GroupFeature;
  selectedFeatures: Record<string, boolean>;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void;
}

const hiddenChilds = ['buys.anulatePoints', 'reports.members', 'payments.mercadoPago'];

const FeatureGroup: FC<Props> = ({ groupFeature, selectedFeatures, setFieldValue }) => {
  const isParentActive = !!selectedFeatures[groupFeature.id];

  return (
    <div className="card card-body rounded-0 mb-3">
      <InputSwitch
        className={`switch-label ${isParentActive ? 'active' : ''}`}
        label={groupFeature.description}
        name={`features.${groupFeature.id}`}
        checked={isParentActive}
        onChange={() => {
          groupFeature.children?.forEach(child => {
            setFieldValue(`features.${child.id}`, !isParentActive);
            child.children?.forEach(grandChild => {
              setFieldValue(`features.${grandChild.id}`, !isParentActive);
            });
          });
        }}
      />
      <div className={`feature-children ${isParentActive ? '' : 'd-none'}`}>
        {groupFeature.children?.map(child => {
          const isChildActive = !!selectedFeatures[child.id];
          return !hiddenChilds.includes(child.name) ? (
            <>
              <InputSwitch
                key={child.id}
                className={`switch-label ${isChildActive ? 'active' : ''}`}
                label={child.description.replace(`${groupFeature.description} |`, '')}
                name={`features.${child.id}`}
                checked={isChildActive}
                onChange={() => {
                  child.children?.forEach(grandChild => {
                    setFieldValue(`features.${grandChild.id}`, !isChildActive);
                  });
                }}
              />
              {child.children?.map(grandChild => {
                const isGrandChildActive = !!selectedFeatures[grandChild.id];
                return (
                  <div
                    key={grandChild.id}
                    className={`feature-grandchildren ${isChildActive ? '' : 'd-none'}`}
                  >
                    <InputSwitch
                      className={`ml-2 switch-label ${isGrandChildActive ? 'active' : ''}`}
                      label={grandChild.description
                        .replace(`${groupFeature.description} |`, '')
                        .replace(
                          `${child.description.replace(`${groupFeature.description} |`, '')} |`,
                          '',
                        )}
                      name={`features.${grandChild.id}`}
                      checked={isGrandChildActive}
                    />
                  </div>
                );
              })}
            </>
          ) : null;
        })}
      </div>
    </div>
  );
};

export default FeatureGroup;
