import { FC } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import Badge from '../../../components/Badge';
import Col from '../../../components/Col';
import MaterialIconMenu from '../../../components/MaterialIconMenu';
import Row from '../../../components/Row';
import { useDatatable } from '../../../hooks/useDatatable';
import { useModuleHeader } from '../../../hooks/useModuleHeader';
import { plansApi, useGetPlansQuery } from '../shared/services/plansApi';
import { PlanGet, PlansGetResponse } from '../shared/types';
import FiltersForm from './components/FiltersForm';

const ListPage: FC = () => {
  useModuleHeader({
    module: {
      title: 'Planes',
      name: 'plans',
    },
    breadcrumbItems: ['Listado'],
    actions: [{ name: 'Crear', subModuleTo: 'Create', color: 'danger' }],
  });

  const navigate = useNavigate();

  const handleEdit = (plan: PlanGet) => {
    navigate(`/plans/edit/${plan.id}`);
  };

  const columns: TableColumn<PlanGet>[] = [
    {
      name: '#',
      selector: row => row.id,
      maxWidth: '60px',
      minWidth: '30px',
    },
    {
      name: 'Nombre',
      selector: row => row.name,
    },
    {
      name: 'Moneda',
      selector: row => row.currency,
    },
    {
      name: 'Precio',
      selector: row => row.price,
    },
    {
      name: '¿Es plan de prueba?',
      cell: row => {
        const color = row.is_plan_test ? 'success' : 'danger';
        return <Badge color={color} text={row.is_plan_test ? 'Si' : 'No'} />;
      },
    },
    {
      name: 'Acciones',
      cell: row => {
        const actions = [{ label: 'Editar', onClick: handleEdit }];

        return <MaterialIconMenu row={row} actions={actions} />;
      },
      maxWidth: '90px',
      minWidth: '30px',
    },
  ];

  const { data, progressPending, onFilter, ...datatableProps } = useDatatable<PlansGetResponse>({
    useService: useGetPlansQuery,
    service: plansApi.endpoints.getPlans,
  });

  return (
    <Row>
      <Col lg={12} className="mb-3">
        <div className="card rounded-0">
          <div className="card-body">
            <h4 className="card-title mb-0">Filtros de búsqueda</h4>
            <hr className="my-3" />
            <FiltersForm onFilter={onFilter} loading={progressPending} />
          </div>
        </div>
      </Col>
      <Col lg={12}>
        <DataTable
          columns={columns}
          data={data?.data || []}
          progressPending={progressPending}
          paginationPerPage={data?.meta?.per_page}
          paginationTotalRows={data?.meta?.total}
          {...datatableProps}
          noDataComponent={
            <div className="rdt_NoData">
              <p>No hay resultados encontrados</p>
            </div>
          }
        />
        ;
      </Col>
    </Row>
  );
};

export default ListPage;
