import { ErrorMessage, useField } from 'formik';
import { FC } from 'react';
import Feedback from '../Feedback';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  placeholder?: string;
  extraInfo?: string;
  [x: string]: unknown;
}

const InputSearch: FC<Props> = ({ label, extraInfo, ...props }) => {
  const [field] = useField(props);

  return (
    <div className="form-group">
      {label && (
        <label htmlFor={props.id || props.name}>
          {label}{' '}
          {extraInfo && (
            <i data-toggle="tooltip" title={extraInfo} className="fas fa-question-circle"></i>
          )}
        </label>
      )}
      <div className="search-bar">
        <input type="search" placeholder={props.placeholder || 'Buscar'} {...field} {...props} />
        <i className="search-icon text-muted i-Magnifi-Glass1"></i>
      </div>
      <ErrorMessage name={props.name}>
        {message => <Feedback type="invalid" message={message} />}
      </ErrorMessage>
    </div>
  );
};

export default InputSearch;
